import React from "react"

import SEO from "../components/seo"

import {FaCloudDownloadAlt, FaChrome} from 'react-icons/fa';

import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

import $ from 'jquery';
if (typeof window !== `undefined`) {
  window.jQuery = $;
  window.$ = $;
  //window.WOW = WOW;
}

const images = [
  {
    original: 'images/screenshots/1.png',
    thumbnail: 'images/screenshots/1.png',
  },
  {
    original: 'images/screenshots/2.png',
    thumbnail: 'images/screenshots/2.png',
  },
  {
    original: 'images/screenshots/3.png',
    thumbnail: 'images/screenshots/3.png',
  },
];

export default class IndexPage extends React.Component {

componentDidMount() {  
  if (typeof window !== `undefined`) {
/*
  import ('../js/jquery.min.js').then(console.log('imported jquery'))
  import ('../js/TweenMax.min.js').then(console.log('imported TweenMax'))
  import ('../js/ScrollToPlugin.min.js').then(console.log('imported ScrollToPlugin'))
  import ('../js/webcomponents.js').then(console.log('imported webcomponents'))
  import ('wowjs/dist/wow.min.js').then(wow => new wow.WOW().init())
  import ('../js/featherlight.min.js').then(console.log('imported featherlight'))


  var $window = $(window);
  var scrollTime = 0.2;
  var scrollDistance = 270;
  $window.on("mousewheel DOMMouseScroll", function(event)
  {

    event.preventDefault(); 
    var delta = event.originalEvent.wheelDelta/120 || -event.originalEvent.detail/3;
    var scrollTop = $window.scrollTop();
    var finalScroll = scrollTop - parseInt(delta*scrollDistance);
    TweenMax.to($window, scrollTime, 
    {
      scrollTo : { y: finalScroll, autoKill:true }, ease: Power1.easeOut, overwrite: 5   
      
    });
  });*/
  }
}

render(){
  return(  <>
    <SEO title="Video Timestamps Search & Browser Extension" />
    <div style={{width: '100%'}}>
      <div className="cover-image"></div> 
      <div className="desktop-fab-container ">
          <a href="https://videotimestamps.com/download">
            <paper-fab className="wow fadeInUp desktop-fab" icon="shop"></paper-fab>
          </a>
      </div>
      
      <div className="wow fadeInUp content-card">
        <a href="https://videotimestamps.com/download">
          <paper-fab className="mobile-fab" icon="shop"></paper-fab>
        </a>
        
        <div className="icon-and-title-flex">
          <img src="images/256x256.png" className="appicon" alt="VideoTimestamps logo"/>
          <div className="title-container">
            <span className="text-title">Video Timestamps</span>
            <br /><div className="intertext-padding"></div>
            <span className="text-subtitle">Browser Extension</span>
            <br /><div className="intertext-padding"></div>
            <span className="text-subtitle">FREE</span>
          </div> 
        </div>

            {/*<div className="user-login">
              <a href="/create"><FaFingerprint size={60} />Login</a>
            </div>
            <div className="user-register">
              <a href="/create"><FaUserPlus size={60} />Register</a>
            </div>*/}

          <div className="icon-and-title-flex" style={{marginTop: '50px'}}>
              <FaChrome size={60} />
            
            <div className="user-download" style={{marginLeft: '20px', textDecoration: 'underline'}}>
              <a href="https://chrome.google.com/webstore/detail/video-timestamps/lcaajcnacljidbdlcalnfhckalkohbej" target="_blank" rel="noopener noreferrer">
                Download the extension from Chrome Webstore
              </a>
            </div>
          </div> 

        <br /><br />
        <span className="text-description" style={{fontSize: '1.1em'}}>The <b>Video Timestamps</b> browser extension allows you to 
        bookmark your favorite sections in your favorite videos so you can check them out again and again.
        All your data is backed-up in the cloud so you are not limited to using only one device to access your data.
        Free and easy to use, the Video Timestamps browser extension also lets you discover other users' favorite video bookmarks.   

        </span>    
      </div>
    </div> 
      
      
      <div className="wow fadeInUp content-works">
        
        <ImageGallery items={images} />

      </div>
    
    <div className="wow fadeInUp content-card" style={{marginTop: '0'}}>
        <span className="text-subtitle" style={{fontSize: '2em', fontWeight: '300', color: '#333'}}>Ready to download the extension and to start saving your favorite video moments?</span>
    
        <div className="">
          <div className="center-text"> <a href="https://chrome.google.com/webstore/detail/video-timestamps/lcaajcnacljidbdlcalnfhckalkohbej" target="_blank" rel="noopener noreferrer"><FaCloudDownloadAlt size={120} /></a></div>
          <div className="center-text">Download</div>
        </div>

    </div>

    
    <div className="empty-space">
      <div className="meta-container wow fadeInUp">
        <div className="wow fadeInUp detail-item watermark credits">
            <span className="text-description credits-text" style={{color: '#ffffff'}}>Copyright @{(new Date().getFullYear())} <a href="https://videotimestamps.com" style={{color: '#fff', fontWeight: '700'}}>Video Timestamps</a></span>
        </div>
      </div>  
    </div>
        
  </>  
)}

}

